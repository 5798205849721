import React, { useState, useEffect } from 'react';
import { Container, Card, Button, Form, Alert, Row, Col, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../firebaseConfig';
import { FaArrowLeft, FaSave, FaEye, FaEyeSlash } from 'react-icons/fa';
import Select from 'react-select';
import '../../styles.css';
import { getSellerCodes } from '../../services/sellerService';

//import moment from 'moment'; // For timestamp formatting

const CreateUser = () => {
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    displayName: '',
    phoneNumber: '',
    jobTitle: '',
    role: 'seller',
    sellerCode: ''
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [sellerOptions, setSellerOptions] = useState([]);
  const [validated, setValidated] = useState(false); // For Bootstrap validation
  const [passwordError, setPasswordError] = useState(''); // For password validation feedback
  const [showPassword, setShowPassword] = useState(false); // For toggling password visibility
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSellerCodes = async () => {
      try {
        const sellers = await getSellerCodes();
        const options = sellers.map((seller) => ({
          value: seller.sellerCode,
          label: `${seller.sellerName} (${seller.sellerCode})`,
        }));
        setSellerOptions(options);
      } catch (error) {
        console.error(error.message);
      }
    };
  
    fetchSellerCodes();
  }, []);
  

  const roleOptions = [
    { value: 'buyer', label: 'Buyer' },
    { value: 'seller', label: 'Seller' },
    { value: 'sellerAdmin', label: 'Seller Admin' },
    { value: 'company', label: 'Company' },
    { value: 'companyAdmin', label: 'Company Admin' },
    { value: 'hsAdmin', label: 'HS Admin' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleRoleChange = (selectedOption) => {
    setNewUser({ ...newUser, role: selectedOption ? selectedOption.value : 'seller' });
  };

  const handleSellerCodeChange = (selectedOption) => {
    setNewUser({ ...newUser, sellerCode: selectedOption ? selectedOption.value : '' });
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    let errors = [];
    if (password.length < minLength) {
      errors.push(`at least ${minLength} characters long`);
    }
    if (!hasUpperCase) {
      errors.push('one uppercase letter');
    }
    if (!hasLowerCase) {
      errors.push('one lowercase letter');
    }
    if (!hasNumber) {
      errors.push('one number');
    }
    if (!hasSpecialChar) {
      errors.push('one special character');
    }

    return errors.length > 0 ? `Password must be ${errors.join(', ')}.` : '';
  };

  const handleSave = async (event) => {
    event.preventDefault();

    // Bootstrap validation
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // Validate password
    const passwordValidationError = validatePassword(newUser.password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      setValidated(true);
      return;
    }

    setIsSaving(true);
    setIsSaved(false);
    setSaveError(null);
    setPasswordError('');
    setValidated(false);

    try {
      const createUser = httpsCallable(functions, 'createUser');
      const response = await createUser(newUser);
      if (response.data.error) {
        throw new Error(response.data.error.message);
      }
      setIsSaved(true);
      setNewUser({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        displayName: '',
        phoneNumber: '',
        jobTitle: '',
        role: 'seller',
        sellerCode: ''
      }); // Reset the form
    } catch (error) {
      console.error('Error creating user:', error);
      const errorMessage = error.message || 'An error occurred';
      const errorDetails = error.details || 'No additional details available.';
      setSaveError(`${errorMessage}: ${errorDetails}`); // Combine message and details
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    navigate('/users');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const renderFields = () => {
    return (
      <Row>
        <Col md={6} className="mb-3">
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={newUser.email}
              onChange={handleInputChange}
              isInvalid={validated && !newUser.email}
              readOnly={isSaving}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? "text" : "password"}
                name="password"
                value={newUser.password}
                onChange={handleInputChange}
                isInvalid={validated && (!newUser.password || passwordError)}
                readOnly={isSaving}
                required
              />
              <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {passwordError || 'Password must be at least 8 characters long, include one uppercase letter, one lowercase letter, one number, and one special character.'}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={newUser.firstName}
              onChange={handleInputChange}
              isInvalid={validated && !newUser.firstName}
              readOnly={isSaving}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a first name.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={newUser.lastName}
              onChange={handleInputChange}
              isInvalid={validated && !newUser.lastName}
              readOnly={isSaving}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a last name.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group controlId="displayName">
            <Form.Label>Display Name</Form.Label>
            <Form.Control
              type="text"
              name="displayName"
              value={newUser.displayName}
              onChange={handleInputChange}
              isInvalid={validated && !newUser.displayName}
              readOnly={isSaving}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a display name.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group controlId="phoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              name="phoneNumber"
              value={newUser.phoneNumber}
              onChange={handleInputChange}
              isInvalid={validated && !newUser.phoneNumber}
              readOnly={isSaving}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a phone number.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group controlId="jobTitle">
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type="text"
              name="jobTitle"
              value={newUser.jobTitle}
              onChange={handleInputChange}
              isInvalid={validated && !newUser.jobTitle}
              readOnly={isSaving}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a job title.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6} className="mb-3">
          <Form.Group controlId="role">
            <Form.Label>Role</Form.Label>
            <Select
              options={roleOptions}
              onChange={handleRoleChange}
              value={roleOptions.find(option => option.value === newUser.role)}
              isDisabled={isSaving}
              required
            />
          </Form.Group>
        </Col>
        {(newUser.role === 'seller' || newUser.role === 'sellerAdmin') && (
          <Col md={6} key="sellerCode" className="mb-3">
            <Form.Group controlId="sellerCode">
              <Form.Label>Seller Code</Form.Label>
              <Select
                options={sellerOptions}
                onChange={handleSellerCodeChange}
                value={sellerOptions.find(option => option.value === newUser.sellerCode)}
                isClearable
                isDisabled={isSaving}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    );
  };

  return (
    <Container className="content">
      <h1>Create New User</h1>
      <div className="d-flex justify-content-between mb-3">
        <Button variant="secondary" onClick={handleBack} disabled={isSaving}>
          <FaArrowLeft className="me-2 mb-1" />
          Back
        </Button>
        <Button 
          variant="primary" 
          onClick={handleSave} 
          disabled={isSaving}
          form="createUserForm"
          type="submit"
        >
          {isSaving ? (
            <span className="spinner-border spinner-border-sm"></span>
          ) : (
            <>
              <FaSave className="me-2 mb-1" />
              Save
            </>
          )}
        </Button>
      </div>
      {isSaved && <Alert variant="success">User has been created successfully</Alert>}
      {saveError && <Alert variant="danger">{saveError}</Alert>} {/* Display error message */}
      <Card className="mb-3">
        <Card.Header>New User Information</Card.Header>
        <Card.Body>
          <Form id="createUserForm" noValidate validated={validated} onSubmit={handleSave}>
            {renderFields()}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CreateUser;
