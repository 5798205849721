// src/services/sellerService.js

import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";  // Assuming firebaseConfig correctly exports initialized Firebase app

export const getAllSellers = async () => {
  const getAllSellersCallable = httpsCallable(functions, 'getAllSellers');
  const response = await getAllSellersCallable();
  return response.data;  // This should return an object like { result: { success: true, sellers: [...] } }
};

export const getSellerById = async (sellerId) => {
  const getSellerByIdCallable = httpsCallable(functions, 'getSellerById');
  const response = await getSellerByIdCallable({ sellerId });
  return response.data.seller;
};

export const updateSeller = async (sellerId, sellerData) => {
  const updateSellerCallable = httpsCallable(functions, 'updateSeller');
  const response = await updateSellerCallable({ sellerId, ...sellerData });
  return response.data;
};




// NEW ONES

export const getSellers = async (scope) => {
  try {
    const getSellers = httpsCallable(functions, 'getSellers');
    const response = await getSellers({ scope }); // Pass the scope parameter
    return response.data;
  } catch (error) {
    console.error('Error fetching sellers:', error);
    throw new Error('Failed to fetch sellers');
  }
};

export const getSellerCodes = async () => {
  try {
    const getSellerCodesFunction = httpsCallable(functions, 'getSellerCodes');
    const response = await getSellerCodesFunction();
    return response.data.sellers || [];
  } catch (error) {
    console.error('Error fetching seller codes:', error);
    throw new Error('Unable to fetch seller codes');
  }
};